const contact = {
    title: 'Obter ajuda',
    description: 'Para solicitações de ajuda ou feedback do produto, entre em contato conosco através dos seguintes métodos.',
    discord: {
        title: 'Participe da comunidade Discord',
        description: 'Conecte-se com outros desenvolvedores para encontrar soluções',
        button: 'Entrar',
    },
    github: {
        title: 'Comunique-se no GitHub',
        description: 'Crie uma issue e envie no GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Contato com suporte via e-mail',
        description: 'Envie-nos um e-mail para obter mais informações e ajuda',
        button: 'Enviar',
    },
    reserve: {
        title: 'Agende seu horário com a equipe Seitrace',
        description: 'Agende uma sessão para um chat ao vivo',
        button: 'Agendar',
    },
};
export default Object.freeze(contact);
